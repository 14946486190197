import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import ColumnAlternating from '../components/columnAlternatingStyled'
import Card from '../components/card'
import { MDBContainer, MDBRow } from 'mdbreact'
import moment from 'moment'

const Events = ({ data }) => {
  const events = data.allContentfulEvent.edges
  const heroImage = data.heroImage
  const conference = data.conference
  const curDate = moment(new Date()).format('YYYY-MM-DD')
  const futureEvents = events.filter(event => moment(event.node.date).format('YYYY-MM-DD') >= curDate).length > 0 ? true : false
  const post = data.markdownRemark
  
  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={heroImage.childImageSharp.gatsbyImageData}
          title="Events"
          subtitle="Join us at industry conferences, webinars and exhibitions."
          type="events"
        /> 

        {futureEvents &&
          <section className="bg-white">
            <MDBContainer>
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                    Upcoming events
                  </h2>

                  {events.filter(event => moment(event.node.date).format('YYYY-MM-DD') >= curDate).sort((a,b) => moment(a.node.date).format('YYYY-MM-DD') == moment(b.node.date).format('YYYY-MM-DD') ? 0 : moment(a.node.date).format('YYYY-MM-DD') < moment(b.node.date).format('YYYY-MM-DD') ? -1 : 1).map(({ node }, index) => (
                    <ColumnAlternating
                      key={index}
                      title={`<span class="font-alt font-w-400 letter-spacing-1 mb-3 text-large">${moment(node.date).format('MMMM DD, YYYY - HH:mm')}</span> <br /><br /> ${node.title}`}
                      subtitle={node.subtitle}
                      image={node.heroSmall ? node.heroSmall.localFile.childImageSharp.gatsbyImageData : conference.childImageSharp.gatsbyImageData}
                      alt={node.title}
                      titleClass="font-alt font-w-700 letter-spacing-1 mb-3 title-small"
                      subtitleClass="font-w-400 letter-spacing-1 text-medium"
                      link={`/events/` + node.slug + `/`}
                      textCol={'7'}
                      imageCol={'5'}
                      colour="ficon-m4"
                    />
                  ))}
            </MDBContainer>
          </section>
        }

        {post.frontmatter.section.map((sections, index) => (
        <section className="bg-light-blue">
          <MDBContainer>
            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
              On-demand events
            </h2>
            <MDBRow>
            {sections.feature.filter(resource => (resource.title === "Webinar" || resource.title === "Podcast")).map((features, index) => (
               <Card
                  key={index}
                  collg="4"
                  colmd="6"
                  height="7.5rem"
                  title={features.title}
                  subtitle={features.subtitle}
                  description={features.description}
                  image={ features.image ? features.image.childImageSharp.gatsbyImageData : null }
                  alttext={features.alttext}
                  link={features.link ? features.link : features.document.publicURL}
                  titleclass="text-medium"
                  descriptionClass="text-card-small"
                />
                ))}
            </MDBRow>
           </MDBContainer>
        </section>      
        ))}

        <section className="bg-white">
          <MDBContainer>
            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
              Past events
            </h2>
            {events.filter(event => moment(event.node.date).format('YYYY-MM-DD') < curDate).map(({ node }, index) => (
              <ColumnAlternating
                key={index}
                title={`<span class="font-alt font-w-400 letter-spacing-1 mb-3 text-large">${moment(node.date).format('MMMM DD, YYYY - HH:mm')}</span> <br /><br /> ${node.title}`}
                subtitle={node.subtitle}
                image={node.heroSmall ? node.heroSmall.localFile.childImageSharp.gatsbyImageData : conference.childImageSharp.gatsbyImageData}
                alt={node.title}
                titleClass="font-alt font-w-700 letter-spacing-1 mb-3 title-small"
                subtitleClass="font-w-400 letter-spacing-1 text-medium"
                link={`/events/` + node.slug + `/`}
                textCol={'7'}
                imageCol={'5'}
                colour="ficon-m4"
              />
            ))}
          </MDBContainer>
        </section>
      </Layout>
    </div>
  )
}
export default Events

export function Head({ data, location }) {
  const heroImage = data.heroImage
  return (
    <SEO
      title="Macro 4 events"
      description="Macro 4 regularly attends, sponsors and presents at relevant industry and corporate events."
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {    
    allContentfulEvent(
      filter: {publishTo: {elemMatch: {url: {eq: "https://www.macro4.com"}}}, 
      date: {gte: "2022-01-01" }}
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          slug
          title
          subtitle
          date
          heroSmall {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 720, quality: 90)
              }
            }
          }
        }
      }
    }
    conference: file(name: { eq: "conference" }) {
      childImageSharp {
        gatsbyImageData(width: 960, quality: 90)
      }
    }
    heroImage: file(name: { eq: "events-default" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
    markdownRemark(
      frontmatter: {template: {eq: "resources"}, name: {eq: "Videos, webinars and podcasts"}}
    ) {
      frontmatter {
        section {
          title
          subtitle
          placement
          feature {
            title
            subtitle
            date(formatString: "MMMM DD, YYYY")
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            alttext
            linktext
            link
            featured
          }
        }
      }
    } 
  }
`